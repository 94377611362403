<template>
  <div class="topContainer">
    <div class="wrap-categories">
      <div class="container mb-5">
        <div class="row flex-lg-nowrap">
          <loading :active.sync="isLoading"></loading>
          <div class="container">
            <div class="py-3 text-center"
                 v-if="!isLoading && categories.length === 0"
            > <h1 class="h3">{{$t('message.alert_no_records_found')}}</h1>
            </div>
            <b-row>
              <b-col
                :key="index"
                lg="6"
                xl="4"
                md="6"
                sm="12"
                v-for="(category, index) in categories"
                xs="12"
              >
                <div class="card bg-white shadow categoryProduct ribbon ribbon-top">
                  <div class="card-body bg-white">
                    <div class="ribbon-target bg-danger" v-if="category.trial === true">
                      {{$t('message.demo_ribbon')}}
                    </div>
                    <div style="min-height: 450px">
                      <div class="p-lg-3">
                        <div class="text-center p-lg-3 mb-3" :class="getVpsCategory(category.title)">
                          <h3 class="text-center text-bg-color-theme my-1 productCategoryTitle">{{category.title}}</h3>
                        </div>
                      </div>
                      <div class="text-center bg-white">

                        <div class="p-1 px-3 py-5 text-justify" style="line-height: 30px">{{category.description}}</div>
                      </div>
                    </div>
                    <div class=" text-white text-center">
                      <div class="p-lg-3">

                        <router-link :to="{name:'products',params: {slide : calculateNextSlide(category.code)}}" class="nu">
                          <b-button block class="bg-theme-secondary">
                            {{$t('message.start_from')}} <span style="font-size: 22px"> {{category.min | currency('&euro;', 2, { spaceBetweenAmountAndSymbol:
                              true,decimalSeparator: ',' ,thousandsSeparator: '.'})}} </span> <small>{{$t('message.plus_vat')}}</small><br/>
                          </b-button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ApiResolver from '../components/common/ApiResolver';
import Api from '../components/common/Api';
import Cart from "../components/cart/Cart";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'categories',
  components: {Loading},
  data() {
    return {
      cart: null,
      categories: [],
      itemsFilters: {},
      isLoading: false,
    };
  },
  computed: {

  },
  methods: {
    getVpsCategory: function(category) {
      return category;
    },
    calculateNextSlide: function (code){
      switch(true) {
        case code === 'VPS0001' :
          return parseInt(code.substring(3))+1;
        case code === 'VPS0004' :
          return parseInt(code.substring(3))+4;
        case code === 'VPS0007' :
          return parseInt(code.substring(3))+6;
      }
    }
  },
  mounted() {
    this.isLoading = true;
    Api.get(ApiResolver.get('PRODUCTS_CATEGORIES'))
      .then((response) => {
        this.categories = response.data;

        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        //console.log(error);
        this.isLoading = false;
      });
  },
};
</script>
<style scoped>
.nu {
  text-decoration: none;
}
</style>

<style type="text/css">
.ribbon {
  position: relative;
}
.ribbon .ribbon-target {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  background-color: #3699FF;
  -webkit-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
}
.ribbon .ribbon-target > .ribbon-inner {
  z-index: -1;
  position: absolute;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ribbon .ribbon-target:after {
  border-color: #004d9c;
}
.ribbon-ver .ribbon-target {
  padding: 5px 10px;
  min-width: 36px;
  min-height: 46px;
  text-align: center;
}
.ribbon.ribbon-top .ribbon-target {
  border-bottom-right-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
  margin-left: 10px;
}
.ribbon.ribbon-bottom .ribbon-target {
  border-top-right-radius: 0.42rem;
  border-top-left-radius: 0.42rem;
}
.ribbon.ribbon-left .ribbon-target {
  border-top-right-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
}
.ribbon.ribbon-right .ribbon-target {
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
}
.ribbon.ribbon-clip.ribbon-left .ribbon-target {
  left: -10px;
}
.ribbon.ribbon-clip.ribbon-left .ribbon-target .ribbon-inner {
  border-top-right-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
}
.ribbon.ribbon-clip.ribbon-left .ribbon-target .ribbon-inner:before, .ribbon.ribbon-clip.ribbon-left .ribbon-target .ribbon-inner:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: transparent !important;
  bottom: -10px;
}
.ribbon.ribbon-clip.ribbon-left .ribbon-target .ribbon-inner:before {
  border-width: 0 10px 10px 0;
  border-right-color: #181C32 !important;
  left: 0;
}
.ribbon.ribbon-clip.ribbon-right .ribbon-target {
  right: -10px;
}
.ribbon.ribbon-clip.ribbon-right .ribbon-target .ribbon-inner {
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
}
.ribbon.ribbon-clip.ribbon-right .ribbon-target .ribbon-inner:before, .ribbon.ribbon-clip.ribbon-right .ribbon-target .ribbon-inner:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: transparent !important;
  bottom: -10px;
}
.ribbon.ribbon-clip.ribbon-right .ribbon-target .ribbon-inner:before {
  border-width: 0 0 10px 10px;
  border-left-color: #181C32 !important;
  right: 0;
}
.main {
  background-color: #ffffff !important;
}
.Professional {
  background-color: #008BCE;
  color: #ffffff;
}
.Business {
  background-color: #00CFB4;
  color: #ffffff;
}
.Enterprise {
  background-color: #FFA400;
  color: #ffffff;
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {

  .categoryProduct {
    margin-top: 0px !important;
    box-shadow: none !important;
    border: 1px solid #cccccc !important;
    width: 340px !important;
    max-width: 340px !important;

    text-align: left;
    padding: 8px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .productCategoryTitle {
    padding: 4px !important;
    margin-top: 10px !important;
  }
}
</style>
